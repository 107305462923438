import React from 'react'

import { SearchField } from 'App/ui/search_field'
import { useThemeVariant } from 'App/Layout/theme'

type TopSectionProps = {
  //
}

export const TopSection: React.FC<TopSectionProps> = () => {
  const { isDark } = useThemeVariant()

  return (
    <div className="vc_row wpb_row vc_row-fluid vc_custom_1559210763022 vc_row-has-fill hide-sticky vc-row-504195">
      <div className="container et-clearfix" style={{ zIndex: 'auto' }}>
        <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none pl-0 pr-0">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div
                id="header-woo-search-form-885237"
                className="header-woo-search-form hbe hbe-icon-element hide-default-false hide-sticky-false hbe-none"
              >
                <div
                  className="dgwt-wcas-search-wrapp dgwt-wcas-no-submit woocommerce w-100"
                  data-wcas-context="a2e1"
                >
                  <SearchField isDark={isDark} allowVinQuery={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
