import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const errorsRoutes: () => IRoute[] = () => [
  {
    path: '*',
    component: loadable(() =>
      import('App/View/Errors').then(({ Error400 }) => Error400)
    ),
    exact: true,
  },
]
