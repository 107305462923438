import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { useCurrentUser } from '@monorepo/user_module'
import { IMenu } from '../interfaces'
import { fetchMenu } from '../api'
import { TMenuName } from 'App/data/menu'

const QUERY_KEY = 'menu'

export function useMenu<TData = IMenu>(
  menuName: TMenuName,
  options?: UseQueryOptions<IMenu, /*AxiosError*/ any, TData>
) {
  const { data: user } = useCurrentUser()

  return useQuery(
    [QUERY_KEY, menuName, user?.roles] as QueryKey,
    () => fetchMenu(menuName, user),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  )
}
