import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const contactsRoutes: () => IRoute[] = () => [
  {
    path: '/contacts',
    component: loadable(() =>
      import('App/View/Contacts').then(({ Contacts }) => Contacts)
    ),
    exact: true,
  },
]
