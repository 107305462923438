import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const goodCatalogRoutes = (): IRoute[] => [
  {
    path: '/goods',
    component: loadable(() =>
      import('App/View/GoodsCatalog/Brands').then(({ Brands }) => Brands)
    ),
    exact: true,
  },
  {
    path: '/goods/:brandName',
    component: loadable(() =>
      import('App/View/GoodsCatalog/Goods').then(({ Goods }) => Goods)
    ),
    exact: true,
  },
  {
    path: '/goods/:brandName/:number',
    component: loadable(() =>
      import('App/View/GoodsCatalog/GoodDetails').then(
        ({ GoodDetails }) => GoodDetails
      )
    ),
    exact: true,
  },
]
