import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import BaseContainer from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import { ICompany, useOfficesList } from '@monorepo/company_module'
import { SearchField } from './SearchField'
import { CartMenu } from './CartMenu'
import { PhoneLink } from 'App/Components'
// import { OfficesList } from '../../components/offices_list'

type MiddleSectionProps = {
  $isDark?: boolean
  logoImage: string
  offices?: ICompany[]
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.headerBackgroundPrimaryColor};
  padding-top: 10px;
  /* box-shadow: inset ${(props) => props.theme.baseBoxShadow}; */
`

const Container = styled(BaseContainer)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`

const LogoImage = styled.img`
  max-height: 96px !important;
  margin-bottom: 5px;
`

export const MiddleSection: React.FC<MiddleSectionProps> = ({
  $isDark,
  logoImage,
  offices,
}) => {
  const { data: phones = [] } = useOfficesList({
    select: (data) =>
      data
        .flatMap((office) => office.phones)
        .filter((phone) => phone.isMain)
        .filter(
          (phone, index, self) =>
            index === self.findIndex((p) => p.number === phone.number)
        )
        .slice(0, 2),
  })

  return (
    <Wrapper>
      <Container>
        <div className="hbe header-logo hbe-left">
          <Link to="/">
            <LogoImage src={logoImage} alt="" />
          </Link>
        </div>
        <SearchField $isDark={$isDark} />
        <div className="d-flex flex-column">
          {phones.map((phone, idx) => (
            <PhoneLink
              key={idx}
              phone={phone.number}
              style={{ color: 'var(--color-white)' }}
            >
              {(phoneNumber) => (
                <>
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ color: 'var(--color-red)' }}
                  />{' '}
                  {phoneNumber}
                </>
              )}
            </PhoneLink>
          ))}
        </div>
        <CartMenu />
      </Container>
      {/* <Container>
      <OfficesList offices={offices} />
    </Container> */}
    </Wrapper>
  )
}
