import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { Catalogs } from '@monorepo/core/config'
import type { ICatalog } from '../../interfaces'
import { fetchCatalogs } from '../api/fetch_catalogs.api'

const QUERY_KEY = 'ilcats-catalog'

type CatalogsFilter = {
  //
}

const clientId = Catalogs.ILCatsConfig.clientId

export function useILCatsCatalogs<TData = ICatalog[]>(
  filter?: CatalogsFilter,
  options?: UseQueryOptions<ICatalog[], unknown, TData>
) {
  return useQuery(
    [QUERY_KEY, filter] as QueryKey,
    () => fetchCatalogs(clientId ?? ''),
    { ...options, enabled: !!clientId && (options?.enabled ?? true) }
  )
}
