import React from 'react'

type AccordionPropsType = {
  //
}

export const Accordion: React.FC<AccordionPropsType> = ({ children }) => (
  <div className="et-accordion et-clearfix collapsible-false">
    {React.Children.toArray(children).map((item: React.ReactNode, idx) =>
      React.isValidElement(item)
        ? React.cloneElement<any>(item, { active: !false })
        : undefined
    )}
  </div>
)
