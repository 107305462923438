import { getData } from '../data'

export type RefreshTokenResponse = {
  token: string
  refresh_token: string
}

export async function fetchCatalogs(clientId: string, mountPoint: string) {
  try {
    return Promise.resolve(
      getData(clientId).map((catalog) => ({
        ...catalog,
        // pageUrl: `/original-catalogs/${catalog.id}`,
        pageUrl: `${mountPoint}/${catalog.id}`,
      }))
    )
  } catch (error) {
    throw error
  }
}
