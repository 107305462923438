import intersection from 'lodash/intersection'

import { initialState, TMenuName } from '@monorepo/App/data/menu'
import { IUser } from 'packages/user_module'
import { IMenu } from '../interfaces'

const filterMenu = (menu: IMenu, user?: IUser): IMenu => {
  return menu.reduce((acc, item) => {
    if (
      !!item.roles?.length &&
      intersection(item.roles, user?.roles ?? []).length === 0
    ) {
      return acc
    }

    return typeof item.menu !== 'undefined'
      ? acc.concat({ ...item, menu: filterMenu(item.menu, user) })
      : acc.concat(item)
  }, [] as IMenu)
}

export function fetchMenu(menuName: TMenuName, user?: IUser) {
  const menu = initialState[menuName]

  return Promise.resolve(filterMenu(menu, user))
}
