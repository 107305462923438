export function isExternalLink(href: string = ''): boolean {
  try {
    const currentOrigin = window?.location?.origin ?? ''

    return (
      currentOrigin === '' ||
      new URL(href, currentOrigin).origin !== currentOrigin
    )
  } catch (error) {
    return true
  }
}
