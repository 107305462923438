import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const aboutUsRoutes: () => IRoute[] = () => [
  {
    path: '/about-us',
    component: loadable(() => import('App/View/AboutUs')),
    exact: true,
  },
]
