import { QueryKey, useQuery, UseQueryOptions } from 'react-query'

import { Catalogs } from '@monorepo/core/config'
import { ICatalog } from '../../interfaces'
import { fetchCatalogs } from '../api/fetch_catalogs.api'

const QUERY_KEY = 'catalogs-parts-catalog'

type CatalogsFilter = {
  //
}

const clientId = Catalogs.CatalogsPartsConfig.clientId
const mountPoint = Catalogs.CatalogsPartsConfig.mountPoint

export function useCatalogsPartsCatalogs<TData = ICatalog[]>(
  filter?: CatalogsFilter,
  options?: UseQueryOptions<ICatalog[], unknown, TData>
) {
  return useQuery(
    [QUERY_KEY, filter, mountPoint] as QueryKey,
    () => fetchCatalogs(clientId ?? '', mountPoint ?? ''),
    { ...options, enabled: !!clientId && (options?.enabled ?? true) }
  )
}
