import React from 'react'

import {
  ForgotPasswordDTO,
  LoginDTO,
  RegistrationDTO,
  ResetPasswordDTO,
} from '../dto'

export interface IAuthContext {
  isAuthenticated: boolean
  isSwitchedUser: boolean
  login(credentials: LoginDTO): Promise<void>
  logout(): Promise<void>
  refresh(): Promise<void>
  registration(profile: RegistrationDTO): Promise<void>
  forgotPassword(credentials: ForgotPasswordDTO): Promise<void>
  resetPassword(credentials: ResetPasswordDTO): Promise<void>
}

export const AuthContext = React.createContext<IAuthContext | null>(null)
