import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const homeRoutes: () => IRoute[] = () => [
  {
    path: '/',
    component: loadable(() => import('App/View/Home').then(({ Home }) => Home)),
    exact: true,
  },
]
