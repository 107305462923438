import { useMutation, useQueryClient } from 'react-query'

import { updateProfile } from '../api'
import { QUERY_KEY } from './constants'

export function useUpdateProfile() {
  const queryClient = useQueryClient()

  return useMutation(updateProfile, {
    onSuccess: (data, _variables) => {
      queryClient.setQueryData(QUERY_KEY, data)
    },
  })
}
