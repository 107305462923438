import React from 'react'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'

import { FormError } from '@monorepo/application_module/components/form_error'
import { UpdatePasswordDTO } from '../dto'
import { updatePasswordSchema } from '../schema/update_password.schema'
import { updatePassword } from '../api/update_password.api'

type UpdatePasswordProviderProps = {
  onSuccess?(): void | Promise<void>
  onError?(error: FormError): void
  children:
    | ((props: FormikContextType<UpdatePasswordDTO>) => React.ReactNode)
    | React.ReactNode
}

export const UpdatePasswordProvider: React.FC<UpdatePasswordProviderProps> = ({
  children,
  onSuccess,
  onError,
}) => {
  const initialValues: UpdatePasswordDTO = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const onSubmit = async (
    values: UpdatePasswordDTO,
    helpers: FormikHelpers<UpdatePasswordDTO>
  ) => {
    const dto = updatePasswordSchema.cast(values) as UpdatePasswordDTO

    try {
      await updatePassword(dto)
      helpers.setSubmitting(false)
      await onSuccess?.()
    } catch (error) {
      helpers.setSubmitting(false)
      const formError = FormError.isFormError(error) ? error : new FormError()
      onError?.(formError)
    }
  }

  const formik: FormikContextType<UpdatePasswordDTO> =
    useFormik<UpdatePasswordDTO>({
      initialValues,
      onSubmit,
      enableReinitialize: true,
      validateOnMount: true,
      validationSchema: updatePasswordSchema,
    })

  return (
    <FormikProvider value={formik}>
      {typeof children === 'function' ? children(formik) : children}
    </FormikProvider>
  )
}

export function useUpdatePasswordForm() {
  return useFormikContext<UpdatePasswordDTO>()
}
