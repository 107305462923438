import { apiClient } from '@monorepo/core'

import { IUser } from '../interfaces'
import { UpdatePasswordDTO } from '../dto'

export async function updatePassword(dto: UpdatePasswordDTO) {
  try {
    const { data } = await apiClient.post<IUser>('/user/edit', {
      password: dto.password,
      plainPassword: {
        first: dto.newPassword,
        second: dto.confirmPassword,
      },
    })

    return data
  } catch (error) {}
}
