import React from 'react'

import { ImagePlaceholder } from 'App/Components'
import { MenuItem, MenuItemProps } from '../../Menu'

type SuggestionItemProps = MenuItemProps & {
  brandName: string
  article: string
  description?: string
  image?: string
  imageSize: string | number
}

export const SuggestionItem: React.FC<SuggestionItemProps> =
  React.memo<SuggestionItemProps>(
    ({ brandName, article, description, image, imageSize, ...props }) => (
      <MenuItem {...props}>
        <img
          src={image ?? ImagePlaceholder.placeholder}
          alt={image ? '' : description}
          height={`${parseInt(String(imageSize))}px`}
          width={`${parseInt(String(imageSize))}px`}
        />
        <article>
          <h6>
            <strong>{brandName}</strong>
            <em>{article}</em>
          </h6>
          {description && <p>{description}</p>}
        </article>
      </MenuItem>
    )
  )
