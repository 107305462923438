import React from 'react'
// import cn from 'classnames'

import { IMenu } from '@monorepo/menu_module'
import { Link } from 'App/Components'
import { Menu } from './Menu'

import './style.scss'

type PropsType = {
  menu: IMenu
}
// TODO: remove old menu
export const Navigation: React.FC<PropsType> = ({ menu }) => (
  <nav
    id="header-menu-container-622660"
    className="header-menu-container hbe hbe-left one-page-false one-page-offset-0 hide-default-false hide-sticky-false menu-hover-underline submenu-appear-move submenu-hover-none submenu-shadow-true tl-submenu-ind-false sl-submenu-ind-true separator-false top-separator-false"
  >
    {true ? (
      <Menu
        items={menu}
        id="header-menu-622660"
        className="header-menu hbe-inner et-clearfix"
      />
    ) : (
      <ul id="header-menu-622660" className="header-menu hbe-inner et-clearfix">
        <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children depth-0">
          <Link to="/" className="mi-link">
            <span className="txt">Home</span>
            <span className="arrow-down" />
          </Link>
          <ul className="sub-menu">
            <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-3230 current_page_item depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Home 1</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Home 2</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Home 3</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Home 4</span>
                <span className="arrow-down" />
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children depth-0">
          <Link to="/" className="mi-link">
            <span className="txt">Pages</span>
            <span className="arrow-down" />
          </Link>
          <ul className="sub-menu">
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">About us</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Career</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">FAQ</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Contact us 1</span>
                <span className="arrow-down" />
              </Link>
            </li>
            <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
              <Link to="/" className="mi-link">
                <span className="txt">Contact us 2</span>
                <span className="arrow-down" />
              </Link>
            </li>
          </ul>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page depth-0">
          <Link to="/" className="mi-link">
            <span className="txt">Blog</span>
            <span className="arrow-down" />
          </Link>
        </li>
        <li className="menu-item menu-item-type-post_type menu-item-object-page depth-0">
          <Link to="/" className="mi-link">
            <span className="txt">Shop</span>
            <span className="arrow-down" />
          </Link>
        </li>
        <li
          className="menu-item menu-item-type-post_type menu-item-object-page depth-0 mm-true menu-item-27"
          data-width={1200}
          data-position="left"
        >
          <Link to="/" className="mi-link">
            <span className="txt">Elements</span>
            <span className="arrow-down" />
          </Link>
          <div
            id="megamenu-880"
            className="sub-menu megamenu"
            data-width={1200}
            data-position="left"
            style={{ marginLeft: '-659.28125px !important' }}
          >
            <div className="vc_row wpb_row vc_row-fluid stretch_row_content vc-row-963037">
              <div className="container et-clearfix">
                <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
                  <div className="vc_column-inner ">
                    <div className="wpb_wrapper">
                      <div
                        id="mm-container-23505"
                        className="mm-container submenu-hover-none separator-false column-4"
                      >
                        <ul
                          id="menu-elements"
                          className="mm-23505 et-mm et-clearfix"
                        >
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children depth-0">
                            <Link to="/">
                              <span className="hi fas fa-puzzle-piece" />
                              <span className="txt">Elements</span>
                            </Link>
                            <ul className="sub-menu">
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Typography</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">
                                    UI elements part 1
                                  </span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">
                                    UI elements part 2
                                  </span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">
                                    UI elements part 3
                                  </span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Icon boxes</span>
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children depth-0">
                            <Link to="/">
                              <span className="hi fab fa-pushed" />
                              <span className="txt">Elements</span>
                            </Link>
                            <ul className="sub-menu">
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Carousel</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Brands</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Persons</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Testimonials</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Pricing table</span>
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children depth-0">
                            <Link to="/">
                              <span className="hi fas fa-atom" />
                              <span className="txt">Elements</span>
                            </Link>
                            <ul className="sub-menu">
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Infographics</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">
                                    Social &amp; marketing
                                  </span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Banner</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Audio video</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">
                                    Galleries &amp; sliders
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children depth-0">
                            <Link to="/">
                              <span className="hi fab fa-bitcoin" />
                              <span className="txt">Elements</span>
                            </Link>
                            <ul className="sub-menu">
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">
                                    Columns &amp; sections
                                  </span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Google map</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Posts</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">Woocommerce</span>
                                </Link>
                              </li>
                              <li className="menu-item menu-item-type-post_type menu-item-object-page depth-1">
                                <Link to="/" className="mi-link">
                                  <span className="txt">View all</span>
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    )}
  </nav>
)
