import React from 'react'
import cn from 'classnames'

import type { IMenuItem } from '@monorepo/menu_module'
import { Link } from 'App/Components'
import { Menu } from '../'

type PropType = {
  item: IMenuItem
  depth?: number
}

export const MenuItem: React.FC<PropType> = ({ item, depth = 0 }) => (
  <li
    className={cn(
      'menu-item',
      'menu-item-type-custom',
      'menu-item-object-custom',
      // 'current-menu-ancestor',
      // 'current-menu-parent',
      { 'menu-item-has-children': !!item.menu },
      `depth-${depth}`
    )}
  >
    <Link to={item.url ?? ''} external={item.external} className="mi-link">
      <span className="txt">{item.name}</span>
      <span className="arrow-down" />
    </Link>
    {!item.menu || (
      <Menu items={item.menu} depth={depth + 1} className="sub-menu" />
    )}
  </li>
)
