import React from 'react'
import { isMobile } from 'react-device-detect'

type ViberLinkPropsType = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
  phone: string
}

export const ViberLink: React.FC<ViberLinkPropsType> = ({
  phone,
  children,
  title = 'Написать в Viber',
  ...props
}) => (
  <a
    href={
      isMobile
        ? `viber://add?number=${phone.replace(/\D/g, '')}`
        : `viber://chat?number=+${phone.replace(/\D/g, '')}`
    }
    title={title}
    {...props}
  >
    {children}
  </a>
)
