import React, { useMemo } from 'react'
import qs from 'qs'
import { useHistory, useLocation } from 'react-router'
import {
  FormikContextType,
  FormikHelpers,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik'
import formatISO from 'date-fns/formatISO'
import subMonths from 'date-fns/subMonths'

import { useContracts, useCurrentContract } from '../../hooks'
import { IOperationsFilter } from '../../interfaces'

type OperationsFilterProviderProps = {
  children:
    | ((props: FormikContextType<IOperationsFilter>) => React.ReactNode)
    | React.ReactNode
}

export const OperationsFilterProvider: React.FC<OperationsFilterProviderProps> =
  ({ children }) => {
    const location = useLocation()
    const history = useHistory()
    const { data: currentContract } = useCurrentContract()
    const { data: contracts } = useContracts()

    const query = useMemo(() => {
      return qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }) as {
        contractNumber?: string
        startDate?: string
        endDate?: string
      }
    }, [location.search])

    const initialValues: IOperationsFilter = useMemo(() => {
      return {
        contract:
          contracts?.find(
            (contract) =>
              contract.number.toLowerCase() ===
              query.contractNumber?.toLowerCase()
          ) ?? currentContract,
        startDate:
          query.startDate ??
          formatISO(subMonths(new Date(), 3), {
            representation: 'date',
          }),
        endDate:
          query.endDate ?? formatISO(new Date(), { representation: 'date' }),
      }
    }, [
      contracts,
      currentContract,
      query.contractNumber,
      query.endDate,
      query.startDate,
    ])

    const onSubmit = async (
      { contract, ...values }: IOperationsFilter,
      helpers: FormikHelpers<IOperationsFilter>
    ) => {
      const nextQuery = {
        ...query,
        contractNumber: contract?.number,
        ...values,
      }
      const nextSearch = qs.stringify(nextQuery, { addQueryPrefix: true })

      history.push({
        ...location,
        search: nextSearch,
      })
    }

    const formik: FormikContextType<IOperationsFilter> =
      useFormik<IOperationsFilter>({
        initialValues,
        onSubmit,
        enableReinitialize: true,
        validateOnMount: true,
      })

    return (
      <FormikProvider value={formik}>
        {typeof children === 'function' ? children(formik) : children}
      </FormikProvider>
    )
  }

export function useOperationsFilterForm() {
  return useFormikContext<IOperationsFilter>()
}
