import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const blogRoutes: () => IRoute[] = () => [
  {
    path: '/blog',
    component: loadable(() =>
      import('App/View/Blog/PostsList').then(({ PostsList }) => PostsList)
    ),
    exact: true,
  },
  {
    path: '/blog/:slug',
    component: loadable(() =>
      import('App/View/Blog/Post').then(({ Post }) => Post)
    ),
    exact: true,
  },
]
