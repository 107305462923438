import { css } from 'styled-components'

export type StyleVariant = 'light' | 'primary' | 'secondary' | 'grey' | 'dark'

export const variantStyle = css<{ $variant?: StyleVariant }>`
  ${(props) => {
    switch (props.$variant) {
      case 'dark':
        return css`
          border-color: ${(props) => props.theme.colorLightGrey};
          background-color: ${(props) => props.theme.colorGrey};
        `
      case 'grey':
        return css`
          border-color: ${(props) => props.theme.colorDarkGrey};
          background-color: ${(props) => props.theme.colorLightGrey};
        `
      case 'primary':
        return css`
          background-color: ${(props) => props.theme.colorMainLight};
        `
      case 'secondary':
        return css`
          border-color: ${(props) => props.theme.colorLightGrey};
          background-color: ${(props) => props.theme.colorMain};
        `
      default:
        return css`
          border-color: ${(props) => props.theme.colorGrey};
          background-color: ${(props) => props.theme.colorWhite};
        `
    }
  }}

  &,h1,h2,h3,h4,h5,h6 {
    ${(props) => {
      switch (props.$variant) {
        case 'dark':
        case 'secondary':
          return css`
            color: ${(props) => props.theme.colorWhite};
          `
        case 'grey':
          return css`
            color: ${(props) => props.theme.colorBlack};
          `
        default:
          return css`
            color: ${(props) => props.theme.colorBlack};
          `
      }
    }}
  }
`
