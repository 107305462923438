import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const staticRoutes: () => IRoute[] = () => [
  {
    path: '/pages/:slug',
    component: loadable(() =>
      import('App/View/StaticPage').then(({ StaticPage }) => StaticPage)
    ),
    exact: true,
  },
]
