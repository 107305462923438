import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import qs from 'qs'

import { apiClient } from '@monorepo/core'

const RESTORE_KEY = Symbol('__RESTORE_SWITCH_USER__')

type UseSwitchUserParams = {
  readonly queryKey: string
  readonly headerKey: string
  readonly updateUrl: boolean
}

const defaultParams: UseSwitchUserParams = {
  queryKey: '_switch_user',
  headerKey: 'x-switch-user',
  updateUrl: false,
}

export function useSwitchUser(options: Partial<UseSwitchUserParams> = {}) {
  const { queryKey, headerKey, updateUrl } = { ...defaultParams, ...options }

  const [switchUser, setSwitchUser] = useState<string | undefined>()
  const location = useLocation<{ [RESTORE_KEY]?: boolean }>()
  const history = useHistory()

  useEffect(() => {
    // if (!updateUrl) {
    //   return
    // }

    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    const nextSwitchUser = query?.[queryKey]
      ? String(query?.[queryKey])
      : undefined
    const isRestore = location.state?.[RESTORE_KEY] ?? false

    if (isRestore) {
      return
    }

    if (nextSwitchUser) {
      setSwitchUser(nextSwitchUser)
      return
    }
  }, [
    history,
    location.search,
    location.state,
    queryKey,
    switchUser,
    updateUrl,
  ])

  // useEffect(() => {
  //   if (!switchUser) {
  //     return
  //   }
  //   const unlisten = history.listen((location) => {
  //     const query = qs.parse(location.search, { ignoreQueryPrefix: true })

  //     if (query[queryKey] === switchUser) {
  //       return
  //     }

  //     const search = qs.stringify(
  //       {
  //         ...query,
  //         [queryKey]: switchUser,
  //       },
  //       { addQueryPrefix: true }
  //     )

  //     history.replace(search)
  //   })

  //   return () => {
  //     if (typeof unlisten === 'function') {
  //       unlisten()
  //     }
  //   }
  // }, [history, queryKey, switchUser])

  useEffect(() => {
    const handler = apiClient.interceptors.request.use((config) => {
      if (switchUser) {
        config.headers[headerKey] = switchUser
      }

      return config
    })

    return () => {
      apiClient.interceptors.request.eject(handler)
    }
  }, [headerKey, switchUser])

  return Boolean(switchUser)
}
