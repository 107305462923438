import styled from 'styled-components'

import { SuggestionItem as BaseSuggestionItem } from './SuggestionItem'

export const SuggestionItem = styled(BaseSuggestionItem)`
  & > img {
    height: ${(props) => parseInt(String(props.imageSize))}px;
    width: ${(props) => parseInt(String(props.imageSize))}px;
    object-fit: contain;
    align-self: start;

    padding: 0.25rem;
    background-color: ${(props) => props.theme.colorWhite};
    border: 1px solid ${(props) => props.theme.colorLightGrey};
    border-radius: 0.25rem;
    max-width: 100%;
  }

  & > article {
    padding-left: 0.75rem;
    flex-grow: 1;

    & > h6 {
      display: flex;
      margin-bottom: 0.25rem;

      & > * {
        color: ${(props) => props.theme.colorWhite};
        padding: 0 0.5rem;

        &:first-child {
          background-color: ${(props) => props.theme.colorRed};
        }
        &:last-child {
          background-color: ${(props) => props.theme.colorMainLight};
          text-transform: uppercase;
        }
      }
    }

    & > p {
      color: ${(props) => props.theme.colorBlack};
      font-weight: ${(props) => props.theme.fontNormal};
      font-size: 0.9em;
      font-style: oblique;
      line-height: 1.5em;
      white-space: normal;
      margin: 0;
    }
  }
`
