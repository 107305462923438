import type { IRoute } from './interface'

import { authRoutes } from './auth'
import { aboutUsRoutes } from './about-us'
import { blogRoutes } from './blog'
import { cabinetRoutes } from './cabinet'
import { cartRoutes } from './cart'
import { contactsRoutes } from './contacts'
import { homeRoutes } from './home'
import { ordersRoutes } from './order'
import { searchRoutes } from './search'
import { staticRoutes } from './static'
import { laximoRoutes } from './laximo'
import { errorsRoutes } from './errors'
import { goodCatalogRoutes } from './goods_catalog'
import { originalCatalogs } from './original-catalogs'

const routes: IRoute[] = [
  ...aboutUsRoutes(),
  ...authRoutes(),
  ...blogRoutes(),
  ...cabinetRoutes(),
  ...cartRoutes(),
  ...contactsRoutes(),
  ...homeRoutes(),
  ...ordersRoutes(),
  ...searchRoutes(),
  ...laximoRoutes(),
  ...staticRoutes(),
  ...goodCatalogRoutes(),
  ...originalCatalogs(),
  ...errorsRoutes(),
]

export default routes
