import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const cabinetRoutes: () => IRoute[] = () => [
  {
    exact: true,
    path: '/cabinet',
    component: loadable(() => import('App/View/Cabinet/Dashboard')),
  },
  {
    exact: true,
    path: '/balance',
    component: loadable(() => import('App/View/Cabinet/Balance')),
  },
  {
    exact: true,
    path: '/garage',
    component: loadable(() => import('App/View/Cabinet/Garage')),
  },
  {
    exact: true,
    path: '/orders',
    component: loadable(() => import('App/View/Cabinet/Orders')),
  },
  {
    exact: true,
    path: '/profile',
    component: loadable(() => import('App/View/Cabinet/Profile')),
  },
]
