import React from 'react'

import type { IMenu } from '@monorepo/menu_module'
import { MenuItem } from './MenuItem'

type PropsType = {
  id?: string
  className?: string
  items: IMenu
  depth?: number
}

export const Menu: React.FC<PropsType> = ({ items, depth = 0, ...props }) => (
  <ul {...props}>
    {items.map((item, idx) => (
      <MenuItem key={idx} item={item} depth={depth} />
    ))}
  </ul>
)
