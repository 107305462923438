import { string, ref, object, SchemaOf } from 'yup'
import { UpdatePasswordDTO } from '../dto/update_password.dto'

export const updatePasswordSchema: SchemaOf<UpdatePasswordDTO> = object().shape(
  {
    password: string()
      .label('Текущий пароль')
      // .min(6)
      .matches(/[0-9a-zA-Z]/)
      .required(),
    newPassword: string()
      .label('Новый пароль')
      .min(6)
      .matches(/[0-9a-zA-Z]/)
      .required(),
    confirmPassword: string()
      .label('Подтверждение нового пароля')
      .oneOf([ref('newPassword')], 'Пароли должны совпадать')
      .required(),
  }
)

// export interface UpdatePasswordDTO {
//   readonly password: string
//   readonly plainPassword: {
//     readonly first: string
//     readonly second: string
//   }
// }

// export const updatePasswordSchema: SchemaOf<UpdatePasswordDTO> = object().shape(
//   {
//     password: string()
//       .label('Текущий пароль')
//       // .min(6)
//       .matches(/[0-9a-zA-Z]/)
//       .required(),
//     plainPassword: object().shape({
//       first: string()
//         .label('Новый пароль')
//         .min(6)
//         .matches(/[0-9a-zA-Z]/)
//         .required(),
//       second: string()
//         .label('Подтверждение нового пароля')
//         .oneOf([ref('first')], 'Пароли должны совпадать')
//         .required(),
//     }),
//   }
// )
