import React, { isValidElement } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

// import { MenuItemType } from '@monorepo/deprecated/interfaces'
import { Link } from 'App/Components'

import style from './style.module.scss'

type LinkItemProps = {
  item: any
}

export const LinkItem: React.FC<LinkItemProps> = ({ item }) => (
  <li className={style.container}>
    <div className="icon-wrap">
      <div className="icon small">
        {item.icon ? (
          isValidElement(item.icon) ? (
            item.icon
          ) : (
            <FontAwesomeIcon icon={item.icon as IconProp} />
          )
        ) : (
          <FontAwesomeIcon icon={faChevronRight} />
        )}
      </div>
    </div>
    <div>
      <Link to={item.url}>{item.name}</Link>
    </div>
  </li>
)
