import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const laximoRoutes: () => IRoute[] = () => [
  {
    exact: true,
    path: '/catalogs',
    component: loadable(() => import('App/View/Laximo/catalogs_list.page')),
  },
  {
    exact: true,
    path: '/catalogs/:code',
    component: loadable(() => import('App/View/Laximo/catalog.page')),
  },
  {
    exact: true,
    path: '/catalogs/vehicles',
    component: loadable(() => import('App/View/Laximo/vehicles_list.page')),
  },
  {
    exact: true,
    path: '/catalogs/vehicle',
    component: loadable(() => import('App/View/Laximo/vehicle')),
  },
  {
    exact: true,
    path: '/catalogs/unit',
    component: loadable(() => import('App/View/Laximo/unit.page')),
  },
]
