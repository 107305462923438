import { apiClient } from '@monorepo/core'

import { IUser } from '../interfaces'
import { UpdateProfileDTO } from '../dto'

export async function updateProfile(dto: UpdateProfileDTO) {
  try {
    const { data } = await apiClient.post<IUser>('/user/edit', dto)

    return data
  } catch (error) {}
}
