import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import {
  useCurrentContract,
  useContracts,
  IContract,
  useCurrentContractSelector,
} from '@monorepo/contract_module'
import { Dropdown } from 'App/Components'

type ContractSwitcherProps = {
  //
}

export const ContractsButton = styled(Dropdown.Button).attrs((props) => ({
  id: props.id ?? 'contracts-dropdown',
  // danger: props.danger ?? false,
}))<{ $danger?: boolean }>`
  /* */
`
export const Contract = styled(Dropdown.Item)`
  display: flex;
  flex-direction: column;
`

export const ContractSwitcher: React.FC<ContractSwitcherProps> = () => {
  const { data: currentContract, isFetching: isCurrentContractFetching } =
    useCurrentContract()
  const { selectContract } = useCurrentContractSelector()

  const { data: orderContracts, isFetching: isContractsFetching } =
    useContracts({
      select: (contracts) =>
        contracts.filter((contract) => contract.isForOrder),
    })
  const { formatNumber } = useIntl()

  const getBalance = (contract: IContract) =>
    `${contract.balance < 0 ? 'Долг' : 'Баланс'}: ${formatNumber(
      Math.abs(contract.balance),
      { format: 'RUB' }
    )}`

  return (
    <ContractsButton
      $danger={(currentContract?.balance ?? 0) < 0}
      title={
        isCurrentContractFetching || isContractsFetching
          ? 'Баланс: загрузка...'
          : currentContract
          ? getBalance(currentContract)
          : 'Баланс: неизвестно'
      }
      menuAlign="right"
      disabled={(orderContracts?.length ?? 0) === 0}
    >
      <Dropdown.Header>Список договоров:</Dropdown.Header>
      <Dropdown.Divider />
      {orderContracts?.map((item, idx) => (
        <Contract
          key={idx}
          eventKey={item.number}
          active={currentContract?.number === item.number}
          onSelect={selectContract.bind(null, item)}
        >
          <span>{item.name}</span>
          <small>{getBalance(item)}</small>
        </Contract>
      ))}
    </ContractsButton>
  )
}
