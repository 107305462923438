import React from 'react'

type PhoneLinkProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
  phone: string
  children?: React.ReactNode | ((phone: string) => React.ReactNode)
}

export const PhoneLink: React.FC<PhoneLinkProps> = ({
  phone,
  children,
  ...props
}) => {
  const cleanPhoneNumber = phone.replace(/[^+0-9]?/g, '')
  const formattedPhoneNumber = cleanPhoneNumber.replace(
    // /(\+?\d)?(\d{3})(\d{3})(\d{2})(\d{2})/,
    // '$1($2) $3-$4-$5'
    /(\+?\d)?(\d{3})(\d{5,})/,
    '$1($2) $3'
  )

  return (
    <a href={`tel:${cleanPhoneNumber}`} {...props}>
      {typeof children === 'undefined'
        ? formattedPhoneNumber
        : typeof children === 'function'
        ? children(formattedPhoneNumber)
        : children}
    </a>
  )
}
