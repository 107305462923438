import styled from 'styled-components'

import type { ButtonOptions } from '../types'
import { BaseBlock } from './base_block'

export const RegularBlock: React.FC<ButtonOptions> = styled(BaseBlock)`
  z-index: 2;
  background-color: ${(props) => {
    return props.$outline
      ? 'transparent'
      : props.$inverted
      ? props.theme.brandWhite
      : props.theme[props.$variant]
  }};
`
