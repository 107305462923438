import { createElement } from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { domToReact } from 'html-react-parser'
import attributesToProps from 'html-react-parser/lib/attributes-to-props'

import { isExternalLink } from '../../../helpers'
import { parserOptions } from '../../htmlParser'
import { RenderFunction } from '../interfaces'

export const render: RenderFunction = (domNode) => {
  const { attribs, children } = domNode

  return isExternalLink(attribs.href)
    ? Object.assign(domNode, {
        attribs: {
          ...attribs,
          target: 'blank',
          rel: 'nofollow noopener',
        },
      })
    : createElement<LinkProps>(
        Link,
        { ...attributesToProps(attribs), to: attribs.href },
        domToReact(children, parserOptions)
      )
}
