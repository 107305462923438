import React from 'react'
import cn from 'classnames'

import style from './style.module.scss'
import { StyleVariant } from 'App/Resources/mixins/variant.mixin'

export type PageSectionProps = Omit<
  React.HTMLAttributes<HTMLElement>,
  'title'
> & {
  title?: React.ReactNode
  description?: React.ReactNode
  $variant?: StyleVariant
  $condensed?: boolean
  fullWidth?: boolean
}

export const PageSection: React.FC<PageSectionProps> = ({
  title,
  description,
  children,
  className,
  fullWidth = false,
  ...props
}) => (
  <div
    {...props}
    className={cn(
      'vc_row wpb_row vc_row-fluid vc_column-gap-24',
      style.container,
      className
    )}
  >
    <div className={cn({ container: !fullWidth }, 'et-clearfix')}>
      <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
        <div className="vc_column-inner ">
          <div className="wpb_wrapper">
            {(title || description) && (
              <div className="mb-4">
                {title && (
                  <h4
                    className={cn(
                      style.title,
                      'et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type'
                    )}
                  >
                    <span className="text-wrapper">
                      <span className="text">{title}</span>
                    </span>
                  </h4>
                )}
                {description && (
                  <p
                    className={cn(
                      style.description,
                      'et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type'
                    )}
                  >
                    <span className="text-wrapper">
                      <span className="text">{description}</span>
                    </span>
                  </p>
                )}
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
)
