import React from 'react'

import { TopSection } from './TopSection'
import { MiddleSection } from './MiddleSection'
import { BottomSection } from './BottomSection'

type FooterProps = {
  logoImage: string
}

export const Footer: React.FC<FooterProps> = ({ logoImage }) => (
  <footer
    id="et-footer-2619"
    className="footer et-footer et-clearfix sticky-false"
    style={{
      color: 'var(--color-white)',
    }}
  >
    <div>
      <TopSection />
      <MiddleSection logoImage={logoImage} />
      <BottomSection />
    </div>
  </footer>
)
