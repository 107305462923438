import { ICatalog } from '../interfaces'

export function getData(clientId: string): ICatalog[] {
  return [
    {
      id: 'to',
      name: 'Каталог ТО',
      url: `https://to.catalogs-parts.com/#{client:${clientId};page:manufacturer;lang:ru}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/to.png',
      isActive: true,
    },
    {
      id: 'lamps',
      name: 'Автолампы',
      url: `https://lamps.catalogs-parts.com/#{client:${clientId};lang:ru}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/lamps.png',
      isActive: true,
    },
    {
      id: 'batteries',
      name: 'Аккумуляторы',
      url: `https://batteries.catalogs-parts.com/#{client:${clientId};page:batteries;lang:ru}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/batteries.png',
      isActive: true,
    },
    {
      id: 'tires',
      name: 'Шины',
      url: `https://tires.catalogs-parts.com/#{client:${clientId};page:tires;lang:ru;group:0}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/tires.png',
      isActive: true,
    },
    {
      id: 'diski',
      name: 'Диски',
      url: `https://wheels.catalogs-parts.com/#{client:${clientId};page:wheels;lang:ru;group:0}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/wheels.png',
      isActive: true,
    },
    {
      id: 'oils',
      name: 'Масла',
      url: `https://oils.catalogs-parts.com/#{client:${clientId};page:oils;lang:ru;group:0}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/oils.png',
      isActive: true,
    },
    {
      id: 'bolty-gayki',
      name: 'Болты, Гайки',
      url: `https://bolts.catalogs-parts.com/#{client:${clientId};page:bolts;lang:ru;group:0}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/bolts.png',
      isActive: true,
    },
    {
      id: 'caps',
      name: 'Колпаки',
      url: `https://caps.catalogs-parts.com/#{client:${clientId};page:caps;lang:ru}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/caps.png',
      isActive: false,
    },
    {
      id: 'aksessuary',
      name: 'Аксессуары',
      url: `https://accessories.catalogs-parts.com/#{client:${clientId};page:manufacturer;lang:ru}`,
      image: 'https://img.catalogs-parts.com/images/cp_brands/accessories.png',
      isActive: true,
    },
  ]
}
