import React from 'react'
import styled from 'styled-components'

import { PageSection as Base, PageSectionProps } from './PageSection'
import { variantStyle } from 'App/Resources/mixins/variant.mixin'

export * from './PageSection'

export const Section: React.FC<PageSectionProps> = styled(Base).attrs(
  (props) => ({
    $variant: props.$variant ?? 'light',
  })
)`
  padding-top: ${(props) => (props.$condensed ? 0 : 3)}rem;
  padding-bottom: ${(props) => (props.$condensed ? 0 : 1.5)}rem;

  ${() => variantStyle}
`
