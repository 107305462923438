import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import styled, { keyframes } from 'styled-components'

const ScrollToTopIconAnimation = keyframes`
  from {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px rgba(white, 0.5);
    filter: opacity(85%);
  }
  50% {
    border: 1px solid white;
    box-shadow: 0px 0px 3px 1px rgba(white, 0.5);
    filter: opacity(50%);
  }
  to {
    border: 2px solid white;
    box-shadow: 0px 0px 4px 3px rgba(white, 0.5);
    filter: opacity(85%);
  }
`

export const ScrollToTopIcon = styled(FontAwesomeIcon).attrs({
  size: '3x',
  icon: faArrowAltCircleUp,
})`
  background-color: ${(props) => props.theme.light};
  border: 2px solid ${(props) => props.theme.light};
  color: ${(props) => props.theme.primary};
  border-radius: 50%;
  /* transition: 1s ease-in-out; */
  animation: ${ScrollToTopIconAnimation} 2s infinite ease-in-out;

  &:hover {
    filter: none;
    animation: none;
  }
`
