import styled from 'styled-components'
import Color from 'color'

import Base from './bottom_section'

export * from './bottom_section'

export const BottomSection = styled(Base)`
  background-color: ${(props) =>
    Color(props.theme.headerBackgroundPrimaryColor).lighten(0.25).string()};
  backgro
  padding: 5px 0;

  &&& a,
  &&& button {
    color: ${(props) => props.theme.headerSecondaryColor};

    // &:hover {
    //   color: ${(props) => props.theme.headerBackgroundSecondaryColor};
    // }
  }

  &&& .sub-menu {
    a,
    button {
      color: ${(props) => props.theme.headerPrimaryColor};
      &:hover {
        color: ${(props) => props.theme.headerBackgroundSecondaryColor};
      }
    }
  }

  /* height: 40px; */
  display: flex;
  align-items: center;
  z-index: 1;
`
