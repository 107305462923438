import React, { useEffect } from 'react'
import cn from 'classnames'
import { Location } from 'history'
import { Redirect, useLocation } from 'react-router'
import styled from 'styled-components'

import { useAuthenticatation } from '@monorepo/auth_module'
import { IBreadcrumb, Breadcrumbs } from './breadcrumbs'
import { filter } from 'lodash/fp'

export type PagePropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly className?: string
  readonly title?: React.ReactNode
  readonly grid?: boolean
  readonly padding?: boolean
  readonly sidebar?: 'left' | 'right' | 'none'
  readonly small?: boolean
  readonly isFetching?: boolean
  readonly topSpace?: boolean
  readonly bottomSpace?: boolean

  readonly breadcrumbs?: IBreadcrumb[]

  readonly scrollToTop?: boolean
  readonly authenticated?: boolean
}

const Wrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dotted ${(props) => props.theme.colorMainDark};
  /* box-shadow: ${(props) => props.theme.baseBoxShadow}; */
`

const SpaceBlock = styled.div`
  height: 40px;
`

export const Page: React.FC<PagePropsType> = ({
  children,
  title,
  grid = false,
  padding = true,
  sidebar = 'none',
  small = false,
  topSpace = false,
  bottomSpace = false,
  // acl,
  scrollToTop = true,
  authenticated,
  breadcrumbs = [],
  ...props
}) => {
  const { isAuthenticated } = useAuthenticatation()
  const location = useLocation<{ redirectTo?: Location<any> }>()

  useEffect(() => {
    scrollToTop && window.scrollTo(0, 0)
  }, [scrollToTop])

  if (authenticated === true && !isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            redirectTo: location,
          },
        }}
      />
    )
  }

  if (authenticated === false && isAuthenticated) {
    const nextLocation = location.state?.redirectTo ?? '/'

    return <Redirect to={nextLocation} />
  }

  return (
    <div {...props}>
      <Wrapper
      // className={cn(style.container, 'title-section et-clearfix d-shadow')}
      >
        <div className="container et-clearfix">
          <div className="vc_row wpb_row vc_row-fluid vc_row-has-fill vc_column-gap-24 vc-row-606817">
            <div className="wpb_column vc_column_container vc_col-sm-12 text-align-none">
              <div className="vc_column-inner pt-0">
                <div className="wpb_wrapper">
                  <div
                    className="et-breadcrumbs-container tse text-align-left align-left tablet-align-left mobile-align-left"
                    // id="et-breadcrumbs-container-394423"
                  >
                    <Breadcrumbs
                      items={filter<IBreadcrumb>(Boolean)([
                        { title: 'Главная', url: '/' },
                        ...breadcrumbs,
                        title ? { title } : undefined,
                      ])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      {topSpace && <SpaceBlock />}
      <div
        // id="et-content"
        className={cn(
          'content',
          'et-clearfix',
          { 'padding-false': !padding },
          { 'pt-4': padding }
        )}
      >
        <div
          className={cn(`layout-sidebar-${sidebar}`, {
            grid,
            small,
          })}
        >
          {/* <div className="container et-clearfix">{children}</div> */}
          {children}
        </div>
      </div>
      {bottomSpace && <SpaceBlock />}
    </div>
  )
}
