import { apiClient } from '@monorepo/core'
import { FormError } from '@monorepo/application_module/components/form_error'
import { ForgotPasswordDTO } from '../dto'

interface ForgotPasswordResponse {
  message: string
}

export async function forgotPassword(dto: ForgotPasswordDTO) {
  try {
    const { data } = await apiClient.get<ForgotPasswordResponse>(
      '/user/resetpassword',
      { params: dto }
    )

    return data
  } catch (error) {
    throw FormError.create(error, 'Ошибка при восстановлении')
  }
}
