import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const authRoutes: () => IRoute[] = () => [
  {
    exact: true,
    path: '/login',
    component: loadable(() => import('App/View/Auth/Login')),
  },
  {
    exact: true,
    path: '/logout',
    component: loadable(() => import('App/View/Auth/Logout')),
  },
  {
    exact: true,
    path: '/forgotpassword',
    component: loadable(() => import('App/View/Auth/ForgotPassword')),
  },
  {
    exact: true,
    path: '/resetting/reset/:token',
    component: loadable(() => import('App/View/Auth/ResetPassword')),
  },
  {
    exact: true,
    path: '/registration',
    component: loadable(() => import('App/View/Auth/Registration')),
  },
]
