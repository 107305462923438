type CatalogType = {
  clientId?: string
}

const settings: CatalogType = {}

try {
  Object.assign(settings, JSON.parse(process.env.REACT_APP_ILCATS || '{}'))
} catch (error) {}

export const ILCatsConfig = settings
