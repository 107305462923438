import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const searchRoutes: () => IRoute[] = () => [
  {
    path: '/search',
    component: loadable(() => import('App/View/Search')),
  },
]
