import { ICatalog } from '../interfaces'

// type CategoryName =
//   | 'Японские авто'
//   | 'Европейские авто'
//   | 'Американские авто'
//   | 'Корейские авто'
//   | 'Китайские авто'

export function getData(clientId: string): ICatalog[] {
  return [
    {
      id: 'ABARTH',
      name: 'ABARTH',
      url: `https://www.ilcats.ru/abarth/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'AFTERMARKET',
      name: 'AFTERMARKET',
      url: `http://aftermarket.autocats.ru.com/pid/86749/clid/${clientId}`,
      isActive: false,
    },
    {
      id: 'ALFA ROMEO',
      name: 'ALFA ROMEO',
      url: `https://www.ilcats.ru/alfa-romeo/?clid=${clientId}`,
      image: 'Alfa-Romeo-logo-2015-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'ARCTIC-CAT Atvs',
      name: 'ARCTIC-CAT Atvs',
      url: `https://www.ilcats.ru/arctic-cat-atvs/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'AUDI',
      name: 'AUDI',
      url: `https://www.ilcats.ru/audi/?clid=${clientId}`,
      image: 'Audi-logo-2009-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'BAW',
      name: 'BAW',
      url: `https://www.ilcats.ru/baw/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'BMW',
      name: 'BMW',
      url: `https://www.ilcats.ru/bmw/?clid=${clientId}`,
      image: 'BMW-logo-2000-2048x2048.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'BMW Moto',
      name: 'BMW Moto',
      url: `https://www.ilcats.ru/bmw-moto/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'BMWI',
      name: 'BMWI',
      url: `https://www.ilcats.ru/bmwi/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'BYD',
      name: 'BYD',
      url: `https://www.ilcats.ru/byd/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'CAN-AM Atvs',
      name: 'CAN-AM Atvs',
      url: `https://www.ilcats.ru/can-am-atvs/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'CAN-AM Moto',
      name: 'CAN-AM Moto',
      url: `https://www.ilcats.ru/can-am-moto/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'CHERY',
      name: 'CHERY',
      url: `https://www.ilcats.ru/chery/?clid=${clientId}`,
      isActive: false,
      category: 'Китайские авто',
    },
    {
      id: 'CHEVROLET',
      name: 'CHEVROLET',
      url: `https://www.ilcats.ru/chevrolet/?clid=${clientId}`,
      image: 'Chevrolet-logo-2013-2560x1440.png',
      isActive: true,
      category: 'Американские авто',
    },
    {
      id: 'CHRYSLER',
      name: 'CHRYSLER',
      url: `https://www.ilcats.ru/chrysler/?clid=${clientId}`,
      image: 'Chrysler-logo-2010-1920x1080.png',
      isActive: true,
      category: 'Американские авто',
    },
    {
      id: 'CITROEN',
      name: 'CITROEN',
      url: `https://www.ilcats.ru/citroen/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'DAF',
      name: 'DAF',
      url: `https://www.ilcats.ru/daf/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'DATSUN',
      name: 'DATSUN',
      url: `https://www.ilcats.ru/datsun/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'DODGE',
      name: 'DODGE',
      url: `https://www.ilcats.ru/dodge/?clid=${clientId}`,
      isActive: false,
      category: 'Американские авто',
    },
    {
      id: 'FAW',
      name: 'FAW',
      url: `https://www.ilcats.ru/faw/?clid=${clientId}`,
      isActive: false,
      category: 'Китайские авто',
    },
    {
      id: 'FIAT',
      name: 'FIAT',
      url: `https://www.ilcats.ru/fiat/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'FIAT Prof',
      name: 'FIAT Prof',
      url: `https://www.ilcats.ru/fiat-prof/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'FORD',
      name: 'FORD',
      url: `https://www.ilcats.ru/ford/?clid=${clientId}`,
      image: 'Ford-logo-2003-1366x768.png',
      isActive: true,
      category: 'Американские авто',
    },
    {
      id: 'GEELY',
      name: 'GEELY',
      url: `https://www.ilcats.ru/geely/?clid=${clientId}`,
      isActive: false,
      category: 'Китайские авто',
    },
    {
      id: 'GREAT-WALL',
      name: 'GREAT-WALL',
      url: `https://www.ilcats.ru/great-wall/?clid=${clientId}`,
      isActive: false,
      category: 'Китайские авто',
    },
    {
      id: 'HONDA',
      name: 'HONDA',
      url: `https://www.ilcats.ru/honda/?clid=${clientId}`,
      image: 'Honda-logo-1920x1080.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'HONDA Atvs',
      name: 'HONDA Atvs',
      url: `https://www.ilcats.ru/honda-atvs/?clid=${clientId}`,
      isActive: false,
      category: 'Японские авто',
    },
    {
      id: 'HONDA Moto',
      name: 'HONDA Moto',
      url: `https://www.ilcats.ru/honda-moto/?clid=${clientId}`,
      isActive: false,
      category: 'Японские авто',
    },
    {
      id: 'HONDA Scooters',
      name: 'HONDA Scooters',
      url: `https://www.ilcats.ru/honda-scooters/?clid=${clientId}`,
      isActive: false,
      category: 'Японские авто',
    },
    {
      id: 'HYUNDAI',
      name: 'HYUNDAI',
      url: `https://www.ilcats.ru/hyundai/?clid=${clientId}`,
      image: 'Hyundai-logo-silver-2560x1440.png',
      isActive: true,
      category: 'Корейские авто',
    },
    {
      id: 'INFINITI',
      name: 'INFINITI',
      url: `https://www.ilcats.ru/infiniti/?clid=${clientId}`,
      image: 'Infiniti-logo-1989-2560x1440.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'ISUZU',
      name: 'ISUZU',
      url: `https://www.ilcats.ru/isuzu/?clid=${clientId}`,
      image: 'Isuzu-logo-1991-3840x2160.png',
      isActive: true,
      category: 'Корейские авто',
    },
    {
      id: 'JAGUAR',
      name: 'JAGUAR',
      url: `https://www.ilcats.ru/jaguar/?clid=${clientId}`,
      image: 'Jaguar-logo-2012-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'JEEP',
      name: 'JEEP',
      url: `https://www.ilcats.ru/jeep/?clid=${clientId}`,
      image: 'Jeep-logo-green-3840x2160.png',
      isActive: true,
      category: 'Американские авто',
    },
    {
      id: 'KAWASAKI Atvs',
      name: 'KAWASAKI Atvs',
      url: `https://www.ilcats.ru/kawasaki-atvs/?clid=${clientId}`,
      isActive: false,
      category: 'Японские авто',
    },
    {
      id: 'KAWASAKI Moto',
      name: 'KAWASAKI Moto',
      url: `https://www.ilcats.ru/kawasaki-moto/?clid=${clientId}`,
      isActive: false,
      category: 'Японские авто',
    },
    {
      id: 'KIA',
      name: 'KIA',
      url: `https://www.ilcats.ru/kia/?clid=${clientId}`,
      image: 'Kia-logo-2560x1440.png',
      isActive: true,
      category: 'Корейские авто',
    },
    {
      id: 'LANCIA',
      name: 'LANCIA',
      url: `https://www.ilcats.ru/lancia/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'LEXUS',
      name: 'LEXUS',
      url: `https://www.ilcats.ru/lexus/?clid=${clientId}`,
      image: 'Lexus-logo-1988-1920x1080.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'LIFAN',
      name: 'LIFAN',
      url: `https://www.ilcats.ru/lifan/?clid=${clientId}`,
      isActive: false,
      category: 'Китайские авто',
    },
    {
      id: 'maintenance',
      name: 'maintenance',
      url: `https://www.ilcats.ru/maintenance/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'MAZDA',
      name: 'MAZDA',
      url: `https://www.ilcats.ru/mazda/?clid=${clientId}`,
      image: 'Mazda-logo-1997-1920x1080.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'MERCEDES',
      name: 'MERCEDES',
      url: `https://www.ilcats.ru/mercedes/?clid=${clientId}`,
      image: 'Mercedes-Benz-logo-2011-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'MINI',
      name: 'MINI',
      url: `https://www.ilcats.ru/mini/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'MITSUBISHI',
      name: 'MITSUBISHI',
      url: `https://www.ilcats.ru/mitsubishi/?clid=${clientId}`,
      image: 'Mitsubishi-logo-2000x2500.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'NISSAN',
      name: 'NISSAN',
      url: `https://www.ilcats.ru/nissan/?clid=${clientId}`,
      image: 'Nissan-logo-2013-1440x900.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'OPEL',
      name: 'OPEL',
      url: `https://www.ilcats.ru/opel/?clid=${clientId}`,
      image: 'Opel-logo-2009-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'PEUGEOT',
      name: 'PEUGEOT',
      url: `https://www.ilcats.ru/peugeot/?clid=${clientId}`,
      image: 'Peugeot-logo-2010-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'PLYMUTH',
      name: 'PLYMUTH',
      url: `https://www.ilcats.ru/plymouth/?clid=${clientId}`,
      isActive: false,
      category: 'Американские авто',
    },
    {
      id: 'POLARIS Atvs',
      name: 'POLARIS Atvs',
      url: `https://www.ilcats.ru/polaris-atvs/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'PORSCHE',
      name: 'PORSCHE',
      url: `https://www.ilcats.ru/porsche/?clid=${clientId}`,
      image: 'Porsche-logo-2008-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'RENAULT-TRUCKS',
      name: 'RENAULT-TRUCKS',
      url: `https://www.ilcats.ru/renault-trucks/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'ROLLS-ROYCE',
      name: 'ROLLS-ROYCE',
      url: `https://www.ilcats.ru/rolls-royce/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'SAAB',
      name: 'SAAB',
      url: `https://www.ilcats.ru/saab/?clid=${clientId}`,
      image: 'Saab-logo-2013-2000x450.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'SEAT',
      name: 'SEAT',
      url: `https://www.ilcats.ru/seat/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'SKANIA',
      name: 'SKANIA',
      url: `https://www.ilcats.ru/skania/?clid=${clientId}`,
      isActive: false,
      category: 'Корейские авто',
    },
    {
      id: 'SKODA',
      name: 'SKODA',
      url: `https://www.ilcats.ru/skoda/?clid=${clientId}`,
      image: 'Skoda-logo-2016-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'SMART',
      name: 'SMART',
      url: `https://www.ilcats.ru/smart/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'SSANGYONG',
      name: 'SSANGYONG',
      url: `https://www.ilcats.ru/ssangyong/?clid=${clientId}`,
      image: 'SsangYong-logo-2560x1440.png',
      isActive: true,
      category: 'Корейские авто',
    },
    {
      id: 'SUBARU',
      name: 'SUBARU',
      url: `https://www.ilcats.ru/subaru/?clid=${clientId}`,
      image: 'Subaru-logo-2003-2560x1440.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'SUZUKI',
      name: 'SUZUKI',
      url: `https://www.ilcats.ru/suzuki/?clid=${clientId}`,
      image: 'Suzuki-logo-5000x2500.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'SUZUKI Atvs',
      name: 'SUZUKI Atvs',
      url: `https://www.ilcats.ru/suzuki-atvs/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'SUZUKI Moto',
      name: 'SUZUKI Moto',
      url: `https://www.ilcats.ru/suzuki-moto/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'SUZUKI Scooters',
      name: 'SUZUKI Scooters',
      url: `https://www.ilcats.ru/suzuki-scooters/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'TOYOTA',
      name: 'TOYOTA',
      url: `https://www.ilcats.ru/toyota/?clid=${clientId}`,
      image: 'Toyota-logo-1989-2560x1440.png',
      isActive: true,
      category: 'Японские авто',
    },
    {
      id: 'VAUXHALL',
      name: 'VAUXHALL',
      url: `https://www.ilcats.ru/vauxhall/?clid=${clientId}`,
      isActive: false,
      category: 'Европейские авто',
    },
    {
      id: 'VICTORY Moto',
      name: 'VICTORY Moto',
      url: `https://www.ilcats.ru/victory-moto/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'VOLKSWAGEN',
      name: 'VOLKSWAGEN',
      url: `https://www.ilcats.ru/vw/?clid=${clientId}`,
      image: 'Volkswagen-logo-2015-1920x1080.png',
      isActive: true,
      category: 'Европейские авто',
    },
    {
      id: 'VOLVO-TRUCKS',
      name: 'VOLVO-TRUCKS',
      url: `https://www.ilcats.ru/volvo-trucks/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'VORTEX',
      name: 'VORTEX',
      url: `https://www.ilcats.ru/vortex/?clid=${clientId}`,
      isActive: false,
      category: 'Китайские авто',
    },
    {
      id: 'YAMAHA Atvs',
      name: 'YAMAHA Atvs',
      url: `https://www.ilcats.ru/yamaha-atvs/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'YAMAHA Moto',
      name: 'YAMAHA Moto',
      url: `https://www.ilcats.ru/yamaha-moto/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'YAMAHA Scooters',
      name: 'YAMAHA Scooters',
      url: `https://www.ilcats.ru/yamaha-scooters/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'zinoro',
      name: 'zinoro',
      url: `https://www.ilcats.ru/zinoro/?clid=${clientId}`,
      isActive: false,
    },
    {
      id: 'ZX',
      name: 'ZX',
      url: `https://www.ilcats.ru/zx/?clid=${clientId}`,
      isActive: false,
    },
  ]
}
