/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { FormattedDate } from 'react-intl'

import { Link } from 'App/Components'
// import securityPaymentImage from 'App/Resources/images/security_payment2-1.jpg'

type BottomSectionProps = {
  //
}

export const BottomSection: React.FC<BottomSectionProps> = () => {
  const companySite = window.location.hostname
  const companyName = 'Автогильдия'
  const companyFoundationDate = undefined

  return (
    <div className="vc_row wpb_row vc_row-fluid vc_custom_1559291692163 vc_row-has-fill vc_column-gap-24 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex vc-row-619020">
      <div className="container et-clearfix">
        <div className="wpb_column vc_column_container vc_col-sm-9 text-align-none">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div
                className="et-heading text-align-left tablet-text-align-inherit mobile-text-align-inherit animate-false no-animation-type"
                id="et-heading-299385"
              >
                <span className="text-wrapper">
                  <span className="text">
                    <Link to="/">
                      <strong className="highlight">{companySite}</strong>
                      &nbsp; ©
                      {companyFoundationDate && (
                        <>
                          <FormattedDate
                            value={companyFoundationDate}
                            year="numeric"
                          />
                          &nbsp;&mdash;&nbsp;
                        </>
                      )}
                      <FormattedDate value={Date.now()} year="numeric" /> |{' '}
                      {companyName}. Все права сохранены.
                    </Link>
                  </span>
                </span>
              </div>
              <span className="et-gap et-clearfix et-gap-580697 hide768"></span>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-3 text-align-none">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div
                id="et-image-144004"
                className="et-image alignnone curtain-left"
              >
                <small>Powered by:</small>&nbsp;&nbsp;
                <a href="https://itonix.ru" target="_blank" rel="noopener">
                  <img
                    // width="383"
                    height="30"
                    style={{ maxHeight: 30 }}
                    src="https://itonix.ru/wp-content/uploads/2020/01/logo_itonix_gray_300px.png"
                    alt="itonix.ru"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="wpb_column vc_column_container vc_col-sm-4 text-align-none">
          <div className="vc_column-inner ">
            <div className="wpb_wrapper">
              <div
                id="et-image-144004"
                className="et-image alignnone curtain-left"
              >
                <img
                  width="383"
                  height="45"
                  src={securityPaymentImage}
                  alt="MobiZap"
                />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}
