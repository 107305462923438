import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const originalCatalogs: () => IRoute[] = () => [
  {
    path: '/original-catalogs/:name',
    component: loadable(() =>
      import('App/View/OriginalCatalogs').then(
        ({ OriginalCatalogs }) => OriginalCatalogs
      )
    ),
    exact: true,
  },
  {
    path: '/original-catalogs',
    component: loadable(() =>
      import('App/View/OriginalCatalogs').then(
        ({ OriginalCatalogs }) => OriginalCatalogs
      )
    ),
    exact: true,
  },
]
