import { apiClient } from '@monorepo/core'

import type { LoginDTO } from '../dto'

interface AuthenticateResponse {
  token: string
  refresh_token: string
}

export async function authenticate(params: LoginDTO) {
  try {
    const { data } = await apiClient.post<AuthenticateResponse>(
      '/auth/login',
      params
    )

    return data
  } catch (error) {
    throw error
  }
}
