import React from 'react'
import { Link as BaseLink } from 'react-router-dom'

import type { ButtonOptions } from './types'
import { HoverBlock } from './components/hover'
import { RegularBlock } from './components/regular'
import { TextBlock } from './components/text'
import { Button } from './components/button'

export type CompositButtonProps = Partial<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> & {
  to?: string
  inverted?: ButtonOptions['$inverted']
  outline?: ButtonOptions['$outline']
  size?: ButtonOptions['$size']
  variant?: ButtonOptions['$variant']
  wide?: ButtonOptions['$wide']
  block?: ButtonOptions['$block']
}

const Link = Button.withComponent(BaseLink)

export const CompositButton: React.FC<CompositButtonProps> = ({
  to,
  children,
  disabled: $disabled = false,
  inverted: $inverted = false,
  outline: $outline = false,
  size: $size = 'medium',
  variant: $variant = 'primary',
  wide: $wide = false,
  block: $block = false,
  ...props
}) => {
  const options: ButtonOptions = {
    $disabled,
    $inverted,
    $outline,
    $size,
    $variant,
    $wide,
    $block,
  }

  const inner = (
    <>
      {!$disabled && <HoverBlock {...options} />}
      <RegularBlock {...options} />
      <TextBlock {...options}>{children}</TextBlock>
    </>
  )

  return to ? (
    <Link {...options} className={props.className} style={props.style} to={to}>
      {inner}
    </Link>
  ) : (
    <Button {...props} {...options} disabled={$disabled}>
      {inner}
    </Button>
  )
}
