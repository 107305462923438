import { getImagineUrl } from '@monorepo/application_module/helpers'
import { getData } from '../data'

export type RefreshTokenResponse = {
  token: string
  refresh_token: string
}

export async function fetchCatalogs(clientId: string) {
  try {
    return Promise.resolve(
      getData(clientId).map(({ image, ...catalog }) => ({
        image: image ? getImagineUrl(image, 'car_brand_cards') : undefined,
        ...catalog,
      }))
    )
  } catch (error) {
    throw error
  }
}
