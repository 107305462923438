import trimStart from 'lodash/trimStart'
import { imageURL } from '@monorepo/core/config'

export const getImagineUrl = <T extends string | string[]>(
  image: T,
  filter: string
): T =>
  image instanceof Array
    ? (image.map((image: string) =>
        getImagineUrl(image, filter)
      ) as typeof image)
    : (`${imageURL}/${filter}/${trimStart(
        image as string,
        '/'
      )}` as typeof image)
