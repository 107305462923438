import React from 'react'
import gravatar from 'gravatar'
import Image from 'react-bootstrap/Image'
import { LinkContainer } from 'react-router-bootstrap'
import styled from 'styled-components'
import type { DropdownProps } from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { useCurrentUser } from '@monorepo/user_module'
import { useMenu } from '@monorepo/menu_module'

import { Dropdown } from 'App/Components'

type UserMenuProps = DropdownProps & {
  //
}

const Avatar = styled(Image).attrs({ className: 'mr-2' })`
  border: 2px solid ${(props) => props.theme.colorWhite};
`

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const { data: user, isFetched } = useCurrentUser()

  const { data: menu = [] } = useMenu('cabinet')

  return (
    <Dropdown.Button
      {...props}
      id="user-menu"
      rootCloseEvent="click"
      title={
        user ? (
          <>
            <Avatar
              width={35}
              height={35}
              roundedCircle
              src={gravatar.url(user.email!, { default: 'mp' })}
              alt=""
            />
            {user.firstName}
          </>
        ) : (
          <>
            <FontAwesomeIcon
              icon={faCircleNotch}
              size="lg"
              className="mr-2"
              fixedWidth
              spin
            />
            Загрузка...
          </>
        )
      }
      menuAlign="right"
      disabled={!isFetched}
    >
      {user && (
        <>
          <Dropdown.Header>Здравствуйте, {user.firstName}</Dropdown.Header>
          <Dropdown.Divider />
        </>
      )}
      {menu.map((item, idx) =>
        item.external || !item.url ? (
          <Dropdown.Item
            key={idx}
            href={item.url}
            target={item.external ? '_blank' : undefined}
          >
            {item.name}
          </Dropdown.Item>
        ) : (
          <LinkContainer key={idx} to={item.url}>
            <Dropdown.Item>{item.name}</Dropdown.Item>
          </LinkContainer>
        )
      )}

      <Dropdown.Divider />
      <LinkContainer to="/logout">
        <Dropdown.Item href="/logout">Выйти из системы</Dropdown.Item>
      </LinkContainer>
    </Dropdown.Button>
  )
}

export default UserMenu
