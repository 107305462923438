import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const ordersRoutes: () => IRoute[] = () => [
  {
    exact: true,
    path: '/order/pay/:id',
    component: loadable(() => import('App/View/Orders/pay_order')),
  },
  {
    exact: true,
    path: '/order/details/:id',
    component: loadable(() => import('App/View/Orders/order_details')),
  },
]
