import loadable from '@loadable/component'

import type { IRoute } from './interface'

export const cartRoutes: () => IRoute[] = () => [
  {
    exact: true,
    path: '/cart',
    component: loadable(() => import('App/View/Cart/Cart')),
  },
]
